<template>
  <form @submit.prevent="submit">
    <EmailInput
      v-model="form.email"
      :error="errors.email"
    />
    <PasswordAtomInput
      name="Пароль"
      v-model="form.password"
      :error="errors.password"
    />
    <NuxtLink class="auth__link" :to="{ name: 'auth-restore' }" replace>Забыли пароль?</NuxtLink>
    <BaseButton big color-primary :class="{ pending: loading }">Войти</BaseButton>
  </form>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { login } from '~/api/auth'

const emit = defineEmits(['success', 'failure'])
const setToken = inject('setToken')

const {
  form,
  errors,
  submit,
  loading
} = useForm(login, async data => {
  await setToken(data?.token)
  emit('success', data, form.value)
}, async data => {
  if (data?.code === 'blacklisted') showError(createBlacklistedError())
  emit('failure', data, form.value)
})
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
